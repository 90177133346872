import { ModalControllerContext } from "App";
import AboutCard from "Components/Cards/AboutCard";
import MobileAboutCard from "Components/Cards/MobileAboutCard";
import ListFilled from "Components/Icons/ListFilled";
import SearchFilled from "Components/Icons/SearchFilled";
import TipFilled from "Components/Icons/TipFilled";
import PopularDestinations from "Components/PopularDestinations";
import UI from "Components/UI";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import css from "./NewUser.module.css";

type NewUser = {
  userId: string;
};

export default function NewUser({ userId }: NewUser): JSX.Element {
  const { t } = useTranslation();
  const auth = useSelector(AuthSelectors.get);

  const { tipDialog, tipListDialog, onboardingDialog } = React.useContext(
    ModalControllerContext
  );
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  //   Disabled until first login logic inplace
  //   useEffect(() => {
  //     onboardingDialog();
  //   }, []);

  return (
    <section className={css.Container}>
      <div className={css.Scroll}>
        <h3 className={css.Title}>{""}</h3>
        {userId === auth?.id && (
          <div className={css.NewUserBox}>
            {isPhone && (
              <div>
                <MobileAboutCard
                  icon={<SearchFilled />}
                  title={t("discover-new-destinations")}
                  text={t("discover-new-destinations-text")}
                  extraPadding={true}
                ></MobileAboutCard>
                {/*<div>
                  <MainSearch
                    placeholder="Search"
                    className={css.SearchInput}
                    wrapperClassName={css.SearchInputWrapper}
                  />
                </div>*/}
              </div>
            )}

            {isPhone ? (
              <div>
                <MobileAboutCard
                  icon={<ListFilled />}
                  title={t("lists")}
                  text={t("about-lists")}
                  className={css.WhiteAboutCard}
                >
                  <UI.Button
                    className={css.Button}
                    onClick={() => tipListDialog()}
                  >
                    {t("create-list")}
                  </UI.Button>
                </MobileAboutCard>
              </div>
            ) : (
              <div>
                <AboutCard
                  icon={<ListFilled />}
                  title={t("lists")}
                  text={t("about-lists")}
                >
                  <UI.Button
                    className={css.Button}
                    onClick={() => tipListDialog()}
                  >
                    {t("create-list")}
                  </UI.Button>
                </AboutCard>
              </div>
            )}
            {isPhone ? (
              <div>
                <MobileAboutCard
                  icon={<TipFilled />}
                  title={t("tips")}
                  text={t("about-tips")}
                  className={css.FirstAboutCard}
                >
                  <UI.Button className={css.Button} onClick={() => tipDialog()}>
                    {t("create-tip")}
                  </UI.Button>
                </MobileAboutCard>
              </div>
            ) : (
              <div>
                <AboutCard
                  icon={<TipFilled />}
                  title={t("tips")}
                  text={t("about-tips")}
                >
                  <UI.Button className={css.Button} onClick={() => tipDialog()}>
                    {t("create-tip")}
                  </UI.Button>
                </AboutCard>
              </div>
            )}
          </div>
        )}

        <h4 className={css.Title}>{t("explore-popular-destinations")}</h4>
        {!isPhone ? (
          <PopularDestinations cols={5} limit={10} />
        ) : (
          <PopularDestinations cols={2} limit={10} />
        )}
      </div>
    </section>
  );
}
