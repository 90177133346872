import CardCarousel from "Components/CardCarousel";
import Hero from "Components/Hero";
import Icons from "Components/Icons";
import MainSearch from "Components/MainSearch";
import CityActions from "Store/Actions/CityActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import CitySelectors from "Store/Selectors/CitySelectors";
import LoadingView from "Views/LoadingView";
import NotFoundView from "Views/NotFoundView";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import css from "./CityView.module.css";
import Link from "next/link";

export default function CityView(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const cityId = router.query.cityId as string;
  const city = useSelector(CitySelectors.get(cityId));
  const [pending, setPending] = React.useState(true);
  const auth = useSelector(AuthSelectors.get);
  const [expandedCheck, setExpandedCheck] = React.useState(false);

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const isHalf = useMediaQuery({
    query: "(max-width: 1400px)",
  });
  const location = useRouter();

  const showSection = () => {
    if (expandedCheck && isPhone) {
      return false;
    }
    return true;
  };

  // Fetch city.
  React.useEffect(() => {
    let mounted = true;

    (async () => {
      setPending(true);
      await CityActions.get(dispatch, cityId);

      if (mounted) {
        setPending(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [cityId, dispatch]);

  if (pending) {
    return <LoadingView />;
  }

  if (!city) {
    return <NotFoundView />;
  }

  return (
    <>
      {showSection() && <Hero image={city.image.large ?? ""} height={290} />}
      <section
        className={css.Header}
        style={{ height: !showSection() ? "0px" : "290px" }}
      >
        <div className={css.HeaderContent}>
          {showSection() && (
            <>
              <h1>
                {isPhone && (
                  <>
                    <section className={css.RESIcon}>
                      <Link href={"/home"}>
                        <a>
                          <Icons.RESIcon />
                        </a>
                      </Link>
                    </section>
                    <div
                      className={css.BackArrow}
                      onClick={() => location.back()}
                    >
                      <Icons.BackArrow />
                    </div>
                  </>
                )}
                <div className={css.CityTitle}>{city.name}</div>
              </h1>
              <h3>
                {`${city.tipListCount} ${
                  city.tipListCount > 1 ? t("lists") : t("list")
                } · ${city.tipCount} ${
                  city.tipCount > 1 ? t("tips") : t("tip")
                }`}
              </h3>
            </>
          )}

          {isPhone && (
            <div>
              <MainSearch
                setExpandedCheck={setExpandedCheck}
                placeholder={t("search")}
                className={css.MainSearch}
                wrapperClassName={css.MainSearchWrapper}
                inputClassName={css.MainSearchInput}
              />
            </div>
          )}
        </div>
      </section>

      {showSection() && (
        <section className={css.ListContainer}>
          {auth?.id && (
            <>
              <CardCarousel
                CARDWIDTH={isHalf ? 180 : 200}
                type="TIP_LIST"
                fetchCriterion={{
                  universeId: auth.id,
                  filters: {
                    cityIds: [cityId],
                  },
                  sort: { followerCount: true },
                }}
                title={t("popular-lists-my-universe")}
                cols={isHalf ? 4 : 6}
              />
              <CardCarousel
                CARDWIDTH={isHalf ? 180 : 200}
                type="TIP"
                fetchCriterion={{
                  universeId: auth.id,
                  filters: {
                    cityIds: [cityId],
                  },
                  sort: { followerCount: true },
                  withTipListId: true,
                }}
                title={t("popular-tips-my-universe")}
                cols={isHalf ? 4 : 6}
              />
            </>
          )}
          <CardCarousel
            CARDWIDTH={isHalf ? 180 : 200}
            type="TIP_LIST"
            fetchCriterion={{
              filters: {
                cityIds: [cityId],
              },
              sort: { followerCount: true },
            }}
            title={t("popular-lists-res-inside")}
            cols={isHalf ? 4 : 6}
          />
          <CardCarousel
            CARDWIDTH={isHalf ? 180 : 200}
            type="TIP"
            fetchCriterion={{
              filters: {
                cityIds: [cityId],
              },
              sort: { borrowerCount: true },
              withTipListId: true,
            }}
            title={t("popular-tips-res-inside")}
            cols={isHalf ? 4 : 6}
          />
          <CardCarousel
            CARDWIDTH={isHalf ? 180 : 200}
            type="USER"
            fetchCriterion={{
              filters: { cityIds: [cityId] },
              sort: { followerCount: true, borrowerCount: true },
            }}
            title={t("popular-profiles-res-inside")}
            cols={isHalf ? 4 : 6}
          />
        </section>
      )}
    </>
  );
}
