import React from "react";
import globalCss from "Global.module.css";
import css from "./NotFoundView.module.css";
import { useTranslation } from "react-i18next";

export default function NotFoundView(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={[globalCss.Content, css.Container].join(" ")}>
      <h4>{t("error-title")}</h4>
      <p>{t("error-text")}</p>
    </div>
  );
}
