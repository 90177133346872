import Spinner from "Components/UI/Spinner";
import React from "react";
import css from "./LoadingView.module.css";

const LoadingView = () => {
  return (
    <div className={css.Container}>
      <Spinner />
    </div>
  );
};

export default LoadingView;
