import { NotificationContext } from "App";
import { useRouter } from "next/dist/client/router";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AuthActions from "Store/Actions/AuthActions";
import { signedIn } from "Store/Reducers/AuthReducer";
import { NotFoundView } from "Views";
import LoadingView from "Views/LoadingView";

const ExternalSigninView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const code = router?.query?.code as string;
  const showNotification = useContext(NotificationContext);

  useEffect(() => {
    if (!code) return;
    (async () => {
      try {
        const nextUser = await AuthActions.externalSignUp(code);

        if (!nextUser) {
          showNotification({
            title: "Error",
            message: t("used-email"),
            type: "error",
          });
          router.push("/");
          return;
        }

        dispatch(signedIn(nextUser));

        router.push(`/${nextUser.slug}`);
      } catch (err) {
        //
      }
    })();
  }, [dispatch, code, router, showNotification]);

  if (code === null) {
    return <NotFoundView />;
  }

  return <LoadingView />;
};

export default ExternalSigninView;
