import { ModalControllerContext, NotificationContext } from "App";
import Icons from "Components/Icons";
import ProfileStatistics from "Components/ProfileStatistics";
import UI from "Components/UI";
import { TipListModel } from "Models/TipListModel";
import { UserModel } from "Models/UserModel";
import AuthActions from "Store/Actions/AuthActions";
import TipListActions from "Store/Actions/TipListActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import UserSelectors from "Store/Selectors/UserSelectors";
import { useAppDispatch } from "Store/hooks";
import MiscUtils from "Utils/MiscUtils";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import css from "./StartpageHeader.module.css";

const StartpageHeader = ({
  tipList,
}: {
  tipList: TipListModel | null | undefined;
}) => {
  const { t } = useTranslation();
  const loading = tipList === undefined;

  const location = useRouter();
  const { userId, listId } = location.query;
  const profileView = tipList === null || listId == null;
  const { tipListDialog, editProfileDialog, signInDialog } = useContext(
    ModalControllerContext
  );

  const user = useSelector(UserSelectors.get((userId as string) ?? ""));
  const auth = useSelector(AuthSelectors.get);
  const path = location.pathname.split("/");
  const dispatch = useAppDispatch();
  const showNotification = useContext(NotificationContext);

  const signOut = useCallback(() => {
    location.push("/");
    AuthActions.signOut(dispatch);
  }, [dispatch, location]);

  const myProfile = user?.id === auth?.id;

  const bioLenght = (user && user?.description?.length) ?? 0;

  const followTipList = useCallback(
    async (nextUser: UserModel | null, nextTipList: TipListModel) => {
      if (!nextUser) {
        // Show sign in dialog.
        signInDialog();
        return;
      }

      TipListActions.toggleFollowing(dispatch, nextUser, nextTipList);

      showNotification({
        title: t("success"),
        message: (
          <div style={{ whiteSpace: "pre" }}>
            <strong>
              {!nextTipList.following ? t("following") : t("unfollowed")}
            </strong>
            <Link href={`/${nextTipList.user.slug}/list/${nextTipList.slug}`}>
              <a>&quot;{nextTipList.title}&quot;</a>
            </Link>
          </div>
        ),
        type: !nextTipList.following ? "neutral" : "alert",
      });
    },
    [dispatch, signInDialog, showNotification]
  );

  return (
    <article
      className={css.Content}
      style={{
        paddingBottom: !profileView ? "0px" : `${bioLenght * 0.4 + 200}px`,
      }}
    >
      {!profileView && (
        <section className={css.Navigation}>
          <div className={css.BackButton} onClick={() => location.back()}>
            <Icons.BackArrow />
          </div>
          <div className={css.Title}>
            <p>{tipList?.title}</p>
          </div>
          {tipList && myProfile && (
            <div onClick={() => tipListDialog(tipList)}>
              <Icons.Edit />
            </div>
          )}
          {tipList && !myProfile && (
            <div>
              <UI.Button
                className={css.FollowButton}
                variant="outline"
                color="white"
                onClick={() => followTipList(auth, tipList)}
              >
                {tipList.following ? t("unfollow") : t("follow")}
              </UI.Button>
            </div>
          )}
        </section>
      )}

      {profileView && user && (
        <article className={css.ProfileCard}>
          <div className={css.ProfileTopContainer}>
            <div className={css.Options}>
              {myProfile && (
                <UI.ContextMenu
                  button={
                    <UI.Button variant="text">
                      <Icons.Menu />
                    </UI.Button>
                  }
                  className={css.ContextMenu}
                >
                  <ul className={css.ContextOptions}>
                    <li>
                      <h4>{t("account")}</h4>
                    </li>

                    <li>
                      <UI.Button
                        variant="text"
                        className={css.CreateButton}
                        onClick={() => editProfileDialog()}
                      >
                        <Icons.EditThin className={css.CreateButton} />
                        {t("edit-profile")}
                      </UI.Button>
                    </li>

                    <li>
                      <UI.Button
                        variant="text"
                        onClick={signOut}
                        className={css.SignOut}
                      >
                        <Icons.Logout className={css.SignOut} />
                        {t("log-out")}
                      </UI.Button>
                    </li>
                  </ul>
                </UI.ContextMenu>
              )}
            </div>

            <div className={css.ProfileImage}>
              {user?.profileImage.thumb ? (
                <img
                  src={user?.profileImage.small}
                  alt=""
                  className={css.Image}
                />
              ) : (
                <Icons.Avatar className={css.AvatarIcon} />
              )}
            </div>
          </div>
          <h2>{MiscUtils.getFullName(user)}</h2>
          <h3>
            {[
              t("joined"),
              t(MiscUtils.getMonthString(user?.createdAt.getMonth())),
              user?.createdAt.getFullYear(),
            ].join(" ")}
          </h3>
          <h3>{user.description}</h3>
          <section className={css.ProfileStatisticsContainer}>
            <ProfileStatistics
              statTitles={true}
              myProfile={myProfile}
              user={user}
              className={css.ProfileStatistics}
            />
          </section>
        </article>
      )}
    </article>
  );
};

export default StartpageHeader;
