import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import CityActions from "Store/Actions/CityActions";
import { useDispatch } from "react-redux";
import css from "./CardCarousel.module.css";
import CardStacked from "Components/Cards/CardStacked";
import CarouselButton from "Components/Icons/CarouselButton";
import Button from "Components/UI/Button";
import TipActions from "Store/Actions/TipActions";
import TipListActions from "Store/Actions/TipListActions";
import UserActions from "Store/Actions/UserActions";
import { useMediaQuery } from "react-responsive";

type PopularDestinationsProps = {
  className?: string;
  cols?: number;
  title?: string;
  CARDWIDTH?: number;
  CARDGAP?: number;
  type: "USER" | "TIP" | "TIP_LIST" | "CITY";
  fetchCriterion;
};

const actionTypes = {
  TIP: TipActions,
  TIP_LIST: TipListActions,
  CITY: CityActions,
  USER: UserActions,
};

const CardCarousel = ({
  className,
  cols = 8,
  title = "Popular Destinations",
  CARDWIDTH = 160,
  CARDGAP = 24,
  type,
  fetchCriterion,
}: PopularDestinationsProps): JSX.Element => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<any>([]);
  const [scroll, setScroll] = useState(0);

  const MAXSCROLL = -(values.length - cols) * (CARDWIDTH + CARDGAP);

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const moveCarousel = (value) => {
    if (values.length > cols)
      setScroll((prev) => Math.max(Math.min(prev - value, 0), MAXSCROLL));
  };

  const loadValues = useCallback(
    async (offset) => {
      const [nextValues] = await actionTypes[type].list(dispatch, {
        limit: cols + 3,
        offset: offset,
        ...fetchCriterion,
      });

      const seen = new Set();

      setValues((prev) =>
        [...prev, ...nextValues].filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        })
      );
    },
    [cols, dispatch, type]
  );

  useEffect(() => {
    (async () => loadValues(0))();
  }, [cols, loadValues]);

  // Preventing vertical scroll
  // useEffect(() => {
  //   function onScroll(event: WheelEvent) {
  //     if (values.length > cols) event.preventDefault();
  //   }

  //   const reffered = ref.current;
  //   if (reffered) {
  //     reffered.addEventListener("wheel", onScroll);
  //   }
  //   return () => reffered?.removeEventListener("wheel", onScroll);
  // }, [cols, values.length]);

  const ref = useRef<HTMLElement>(null);
  const refTiles = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = () => {
      const end =
        refTiles.current &&
        refTiles.current?.scrollLeft -
          (refTiles.current?.scrollWidth - refTiles.current?.clientWidth) >=
          0;
      if (end) {
        loadValues(Math.floor(values.length / (cols + 3)));
      }
    };

    if (Math.abs(scroll) > Math.abs(MAXSCROLL + (CARDGAP + CARDWIDTH))) {
      loadValues(Math.floor(values.length / (cols + 3)));
    }
    const refT = refTiles.current;
    refT?.addEventListener("scroll", onScroll);

    return () => {
      refT?.removeEventListener("scroll", onScroll);
    };
  }, [CARDGAP, CARDWIDTH, MAXSCROLL, values.length, loadValues, scroll, cols]);

  if (values.length === 0) {
    return <div></div>;
  }

  return (
    <section
      style={{
        width: !isPhone ? cols * (CARDWIDTH + CARDGAP) - CARDGAP : "auto",
      }}
      ref={ref}
      className={[css.Container, className ?? ""].join(" ")}
    >
      <h4>{title}</h4>

      <div
        // onWheel={(e) => moveCarousel(e.deltaY)}
        className={css.Tiles}
        ref={refTiles}
      >
        {values.map((value) => (
          <div key={value.id} className={css.Card} style={{ left: scroll }}>
            <CardStacked size={CARDWIDTH} value={value} />
          </div>
        ))}
      </div>

      {values.length > cols && !isPhone && (
        <>
          {scroll !== 0 && (
            <Button
              variant="text"
              style={{ top: `${CARDWIDTH / 2}px` }}
              onClick={() => moveCarousel(-3 * (CARDGAP + CARDWIDTH))}
              className={[scroll === 0 && css.Disabled, css.ButtonLeft].join(
                " "
              )}
              disabled={scroll === 0}
            >
              <CarouselButton />
            </Button>
          )}
          <Button
            variant="text"
            style={{ top: `${CARDWIDTH / 2}px` }}
            onClick={() => moveCarousel(3 * (CARDGAP + CARDWIDTH))}
            className={[
              scroll === MAXSCROLL && css.Disabled,
              css.ButtonRight,
            ].join(" ")}
            disabled={scroll === MAXSCROLL}
          >
            <CarouselButton />
          </Button>
        </>
      )}
    </section>
  );
};

export default memo(CardCarousel);
