import TipSmall from "Components/Cards/TipSmall";
import getMarkerEncoded from "Components/Icons/Category/MapCategory";
import { TipModel } from "Models/TipModel";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Services from "Services";
import TipActions from "Store/Actions/TipActions";
import TipSelectors from "Store/Selectors/TipSelectors";
import MobileMapDetails from "../MobileMapDetails/MobileMapDetails";

import css from "./MobileMapView.module.css";
import CategoryFilter from "Components/CategoryFilter";

type markerType = {
  marker: google.maps.Marker;
  categoryId: string;
  tipId: string;
};

const MobileMapView = ({ tipList }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const dispatch = useDispatch();
  const location = useRouter();
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const markers = React.useRef<
    { marker: google.maps.Marker; categoryId: string }[]
  >([]);
  const [selectedTip, setSlectedTip] = useState<TipModel | null>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  const [tipIds, setTipIds] = useState<string[] | null>(null);
  const tips = useSelector(TipSelectors.list(...(tipIds ?? [])));
  const { tipId } = location.query;

  // useEffect(() => {
  //   if (tipId && tips && selectedTip?.id != tipId) {
  //     const targetTip = tips.find((tip) => tip.id === tipId);
  //     if (targetTip) setSlectedTip(targetTip);
  //   }
  // }, [selectedTip?.id, tipId, tips]);

  const cityLat = tipList?.city.latitude;
  const cityLng = tipList?.city.longitude;

  useEffect(() => {
    (async () => {
      setTipIds(null);

      const [nextTips] = await TipActions.list(dispatch, {
        limit: 100,
        filters: { tipListIds: [tipList.id] },
      });

      setTipIds(nextTips.map((tip) => tip.id));
    })();
  }, [dispatch, tipList.id]);

  useEffect(() => {
    (async () => {
      if (
        !mapContainerRef.current ||
        typeof cityLat !== "number" ||
        typeof cityLng !== "number"
      ) {
        return;
      }

      setMap(
        await Services.GoogleMaps.getMap(mapContainerRef.current, {
          lat: cityLat,
          lng: cityLng,
        })
      );
    })();
  }, [cityLat, cityLng]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const latLngs: { lat: number; lng: number }[] = [];
    const oldMarkers = markers.current as markerType[];
    // Set new markers.
    markers.current = tips
      .map((tip) => {
        const { longitude: lng, latitude: lat } = tip.venue ?? {};

        if (typeof lng !== "number" || typeof lat !== "number") {
          return undefined;
        }

        latLngs.push({ lat, lng });

        const marker = new google.maps.Marker({
          map,
          position: { lat, lng },
          icon: getMarkerEncoded(
            tip.category?.icon ?? 4,
            selectedTip?.id === tip.id
          ),
          title: tip.venue?.name,
        });

        marker.addListener("click", () => {
          oldMarkers.forEach((marker) => {
            if (marker.tipId === tip?.id) {
              marker.marker.setMap(null);
            }
          });
          setSlectedTip(tip);
        });

        return {
          marker,
          categoryId: tip.categoryId,
          tipId: tip.id,
        };
      })
      .filter((marker) => marker !== undefined) as markerType[];

    // Remove old markers:
    setTimeout(() => {
      // Avoid flickering.
      oldMarkers.forEach((marker) => {
        marker.marker.setMap(null);
      });
    }, 50);

    if (latLngs.length === 0) {
      return;
    }

    // Fit map.
    const latLngBounds = new google.maps.LatLngBounds(
      {
        lat: Math.min(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.min(...latLngs.map((latLng) => latLng.lng)),
      },
      {
        lat: Math.max(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.max(...latLngs.map((latLng) => latLng.lng)),
      }
    );

    map.fitBounds(latLngBounds);
  }, [tips, map, tipList.id, selectedTip?.id, selectedTip]);

  return (
    <article className={css.Container}>
      <section className={css.Filters}>
        <CategoryFilter
          tips={tips}
          callback={(category) =>
            markers.current.forEach((marker) =>
              marker.marker.setVisible(
                marker.categoryId === category || category === null
              )
            )
          }
        />
      </section>

      {openDetails && (
        <MobileMapDetails
          tip={selectedTip as TipModel}
          tipList={tipList}
          setOpenDetails={setOpenDetails}
        />
      )}
      <section
        className={css.SelectedTipContainer}
        onClick={() => {
          setOpenDetails(true);
        }}
      >
        {selectedTip && (
          <TipSmall
            className={css.SelectedTip}
            tip={selectedTip}
            onClose={() => {}}
          />
        )}
      </section>
      <div ref={mapContainerRef} className={css.Map} />
    </article>
  );
};

export default MobileMapView;
