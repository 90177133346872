import Category from "Components/Icons/Category";
import Followers from "Components/Icons/Followers";
import Star from "Components/Icons/Star";
import Tip from "Components/Icons/Tip";
import Tooltip from "Components/Tooltip";
import { CityModel } from "Models/CityModel";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import { UserModel } from "Models/UserModel";
import { useRouter } from "next/dist/client/router";
import React, { memo, ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import List from "../Icons/List";
import css from "./CardStacked.module.css";

type InfoType = [ReactNode | null, string, string?][];

type CardType = {
  title: string;
  image: string;
  info: InfoType;
  subtitle?: string;
};

const CardStacked = ({
  value,
  size = 160,
}: {
  value: TipModel | TipListModel | UserModel | CityModel;
  size?: number;
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const Card: Partial<CardType> = {};

  if (value.type === "TIP") {
    Card.title = value.title || "";
    Card.image = value.image.medium;

    let info: InfoType = [];

    info.push([
      <Category
        className={css.CategoryIcon}
        key={1}
        icon={value?.category?.icon ?? 100}
      />,
      value.category?.title || "",
    ]);
    info.push([
      <Star key={2} />,
      String(value.borrowerCount) || "",
      `${value.borrowerCount > 1 ? t("borrowers") : t("borrower")}`,
    ]);
    Card.info = info;
  } else if (value.type === "TIP_LIST") {
    Card.title = value.title || "";
    Card.image = value.image.medium;

    let info: InfoType = [];

    (Card.subtitle =
      t("by") + value.user.firstName + " " + value.user.lastName || ""),
      info.push([
        <Followers key={1} />,
        String(value.followerCount) || "",
        `${value.followerCount > 1 ? t("followers") : t("follower")}`,
      ]);
    info.push([
      <Tip key={2} />,
      String(value.tipCount) || "",
      `${value.tipCount > 1 ? t("tips") : t("tip")}`,
    ]);

    Card.info = info;
  } else if (value.type === "USER") {
    Card.title = value.firstName + " " + value.lastName;
    Card.image = value.profileImage.medium;
    Card.subtitle = value.description || "";

    let info: InfoType = [];
    info.push([
      <List key={0} />,
      String(value.tipListCount) || "",
      `${value.tipListCount > 1 ? t("lists") : t("list")}`,
    ]);
    info.push([
      <Followers key={0} />,
      String(value.followerCount) || "",
      `${value.followerCount > 1 ? t("followers") : t("follower")}`,
    ]);
    info.push([
      <Star key={0} />,
      String(value.borrowerCount),
      `${value.borrowerCount > 1 ? t("borrowers") : t("borrower")}`,
    ]);

    Card.info = info;
  } else if (value.type === "CITY") {
    Card.title = value.name;
    Card.image = value.image.medium;

    let info: InfoType = [];
    info.push([
      <List key={0} />,
      String(value.tipListCount) || "",
      `${value.tipListCount > 1 ? t("lists") : t("list")}`,
    ]);
    info.push([
      <Tip key={0} />,
      String(value.tipCount),
      `${value.tipCount > 1 ? t("tips") : t("tip")}`,
    ]);
    Card.info = info;
  }

  const navigate = React.useCallback(
    async (value: UserModel | CityModel | TipListModel | TipModel) => {
      if (value.type === "USER") {
        router.push(`/${value.slug}`);
      } else if (value.type === "CITY") {
        router.push(`/cities/${value.slug}`);
      } else if (value.type === "TIP_LIST") {
        router.push(`/${value.user.slug}/list/${value.slug}`);
      } else if (value.type === "TIP") {
        router.push(
          `/${value.user.slug}/list/${value.tipListSlug}?tipId=${value.slug}`
        );
      }
    },
    [router]
  );

  return (
    <div
      style={{ width: size }}
      className={[css.Container, value.type === "USER" && css.User].join(" ")}
    >
      <div style={{ cursor: "pointer" }} onClick={() => navigate(value)}>
        <img
          style={{
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
          }}
          className={css.Image}
          src={Card.image}
          alt={""}
        />
      </div>

      <div className={css.Info}>
        <h4 onClick={() => navigate(value)} className={css.Title}>
          {Card.title}
        </h4>
        <h5
          onClick={() => value.type === "TIP_LIST" && navigate(value)}
          className={css.Subtitle}
        >
          {Card.subtitle}
        </h5>

        <span className={css.InfoItemContainer}>
          {Card.info?.map((infoItem, index) => (
            <ListItemEntry infoItem={infoItem} index={index} key={index} />
          ))}
        </span>
      </div>
    </div>
  );
};

const ListItemEntry = ({ infoItem, index }) => {
  const { t } = useTranslation();
  const refOne = useRef<HTMLDivElement>(null);
  const [coord, setCoord] = useState({ left: 0, top: 0 });
  const [isOn, setOn] = useState(false);

  const updateTooltipCoords = (refEx) => {
    if (refEx.current !== null) {
      const rect = refEx.current.getBoundingClientRect();
      setCoord({
        left: rect.x - 10,
        top: rect.y + window.scrollY - 20,
      });
    }
  };
  return (
    <React.Fragment key={infoItem[1] + index}>
      <span
        ref={refOne}
        className={css.InfoItem}
        onMouseEnter={() => {
          updateTooltipCoords(refOne);
          setOn(true);
        }}
        onMouseLeave={() => setOn(false)}
      >
        {infoItem[0]}
        <span>{t(infoItem[1])}</span>
      </span>
      {infoItem[2] !== undefined && (
        <Tooltip
          selector={"#tooltip"}
          coord={coord}
          isOn={isOn}
          boxPosition={css.BoxPosition}
        >
          {`${infoItem[1]} ${infoItem[2]}`}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default memo(CardStacked);
