import React, { useState, useEffect, memo } from "react";
import Icons from "Components/Icons";
import Explorers from "Components/Explorers";
import MiscUtils from "Utils/MiscUtils";
import Link from "next/link";
import Services from "Services";
import UI from "Components/UI";
import { useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import css from "./UserLeftColumn.module.css";
import { useMediaQuery } from "react-responsive";
import ProfileStatistics from "Components/ProfileStatistics";
import UserSelectors from "Store/Selectors/UserSelectors";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

type UserLeftColumnProps = {
  onNavigate?: () => void;
};

function UserLeftColumn({ onNavigate }: UserLeftColumnProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const location = useRouter();
  const { userId } = location.query;
  const user = useSelector(UserSelectors.get((userId as string) ?? ""));
  const auth = useSelector(AuthSelectors.get);
  const myProfile = (auth && auth?.id === user?.id) ?? false;

  const allCriterion: Parameters<typeof Services.TipList.list>[0] = {
    filters: { userIds: [(user?.id as string) ?? ""] },
    sort: { followerCount: true },
  };
  const followedCriterion: Parameters<typeof Services.TipList.list>[0] = {
    filters: { followerUserIds: [(user?.id as string) ?? ""] },
    sort: { followerCount: true },
  };
  const [collapsed, setCollapsed] = useState(true);
  const [toggleCollapse, setToggleCollapse] = useState(false);

  const profileLink = `/${user?.slug}`;

  const showCollapse = useMediaQuery({
    query: "(max-width: 1259px)",
  });

  return (
    <section
      className={[
        css.Container,
        showCollapse && collapsed && css.Collapsed,
      ].join(" ")}
      onMouseEnter={() => showCollapse && setCollapsed(false)}
      onMouseLeave={() => !toggleCollapse && showCollapse && setCollapsed(true)}
      style={{
        transform: showCollapse && !collapsed ? "translateX(230px)" : "",
      }}
    >
      <section className={css.Content}>
        <article className={css.User}>
          <Link href={profileLink}>
            <a>
              {user?.profileImage.thumb ? (
                <img
                  src={user?.profileImage.thumb}
                  alt=""
                  className={css.Image}
                />
              ) : (
                <Icons.Avatar className={css.Image} />
              )}
            </a>
          </Link>
          <div className={css.Vstack}>
            <Link href={profileLink}>
              <a className={css.Name} onClick={onNavigate}>
                {MiscUtils.getFullName(user)}
              </a>
            </Link>
            <ProfileStatistics
              user={user}
              myProfile={myProfile}
              className={
                showCollapse && collapsed ? css.CollapsedProfile : undefined
              }
            />
          </div>
        </article>

        <section className={css.Explorers}>
          <section>
            <Explorers.TipList
              title={
                myProfile
                  ? t("my-lists")
                  : `${
                      user?.firstName
                        ? `${MiscUtils.formatNameApos(
                            user.firstName,
                            i18n.language
                          )} `
                        : ""
                    }${t("lists").toLowerCase()}`
              }
              className={css.TipLists}
              fetchCriterion={allCriterion}
              showCity={true}
              hideIcon
            />
          </section>

          <Explorers.TipList
            title={(total) =>
              myProfile
                ? `${t("following-left-column")} ${MiscUtils.quantify(
                    "list",
                    total
                  )}`
                : `${t("lists")} ${t("that").toLowerCase()} ${
                    user?.firstName ? `${user.firstName} ` : ""
                  }${t("follows")}`
            }
            className={css.TipLists}
            fetchCriterion={followedCriterion}
            showCity={true}
            showImage={false}
            hideIcon
          />
        </section>
      </section>
      {showCollapse && (
        <UI.Button
          variant="text"
          className={css.CollapseButton}
          onClick={() => setToggleCollapse((prev) => !prev)}
        >
          <span>
            <Icons.Chevron direction={toggleCollapse ? "right" : "left"} />
            <Icons.Chevron direction={toggleCollapse ? "right" : "left"} />
          </span>
        </UI.Button>
      )}
    </section>
  );
}

export default memo(UserLeftColumn);
