import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotFoundView } from "Views";
import TipListActions from "Store/Actions/TipListActions";
import TipActions from "Store/Actions/TipActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipList from "Views/Common/TipList";
import UserSelectors from "Store/Selectors/UserSelectors";
import css from "./LoggedInView.module.css";
import { TipModel, TipStatusEnum } from "Models/TipModel";
import { useModal } from "Components/UI/Modal";
import UI from "Components/UI";
import { NotificationContext } from "App";
import { useRouter } from "next/dist/client/router";
import NewUser from "Views/LoggedInView/Components/NewUser";
import { useMediaQuery } from "react-responsive";
import UserActions from "Store/Actions/UserActions";
import Spinner from "Components/UI/Spinner";
import MobileUserLists from "./Components/MobileUserLists";
import MobileLayout from "./Components/Layouts/MobileLayout";
import DesktopLayout from "./Components/Layouts/DesktopLayout";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import { useTranslation } from "react-i18next";

export default function LoggedInView(): JSX.Element {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { userId, listId: listSlug } = router.query;
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [tipListSlug, setTipListSlug] = useState(listSlug);
  const user = useSelector(UserSelectors.get(userId as string));
  const tipList = useSelector(
    TipListSelectors.get(tipListSlug as string | undefined)
  );
  const showNotification = React.useContext(NotificationContext);

  const [newUser, setNewUser] = useState(false);

  const auth = useSelector(AuthSelectors.get);
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    (tip: TipModel) => (
      <UI.ConfirmDialog
        message={t("tip-remove")}
        options={
          <>
            <UI.Button
              onClick={async () => {
                try {
                  await TipListActions.removeTips(
                    dispatch,
                    tipList?.id || "",
                    [tip],
                    auth?.id ?? ""
                  );
                  await TipActions.remove(dispatch, tip);
                  showNotification({
                    title: t("success-remove") + tip.title,
                  });
                } catch {
                  showNotification({
                    title: t("error-remove") + tip.title,
                    type: "error",
                  });
                } finally {
                  hideConfirmDialog();
                }
              }}
              color="fourth"
            >
              {t("delete")}
            </UI.Button>
            <UI.Button
              onClick={async () => {
                try {
                  await TipActions.update(
                    dispatch,
                    tip.id,
                    tip.title ?? null,
                    tip.description ?? null,
                    tip.venue?.id ?? null,
                    undefined,
                    tip.imageAttribution ?? null,
                    tip.categoryId ?? null,
                    null,
                    TipStatusEnum.DRAFT
                  );
                  showNotification({
                    title:
                      i18n.language === "en"
                        ? "Successfully moved " + tip.title + " to draft"
                        : "Flyttade " + tip.title + " till utkast",
                  });
                } catch {
                  showNotification({
                    title:
                      i18n.language === "en"
                        ? "An error occurred when moving " +
                          tip.title +
                          " to draft"
                        : "Ett fel uppstod när " +
                          tip.title +
                          " flyttades till utkast",

                    type: "error",
                  });
                } finally {
                  hideConfirmDialog();
                }
              }}
              color="fifth"
            >
              {t("move-to-draft")}
            </UI.Button>
            <UI.Button onClick={() => hideConfirmDialog()}>
              {t("cancel")}
            </UI.Button>
          </>
        }
      />
    )
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Query parameters not found
    if (listSlug === undefined && userId === undefined) return;

    setLoading(true);

    (async () => {
      if (!user) {
        await UserActions.get(dispatch, userId as string);
      }
      if (listSlug && (tipList === null || tipList.slug !== listSlug)) {
        setTipListSlug(listSlug);
        await TipListActions.get(dispatch, listSlug as string);
      } else if (userId && tipList === null) {
        const list = await TipListActions.getMostPopularList(
          dispatch,
          userId as string
        );
        if (list) {
          setTipListSlug(list.slug);
        } else {
          setNewUser(true);
        }
      }
      setLoading(false);
    })();
  }, [dispatch, listSlug, router.query, userId, isPhone]);

  const crumbs: { name: string; path: string; id: string }[] = [];

  if (tipList) {
    if (tipList.city) {
      crumbs.push({
        name: tipList.city.name,
        path: `/cities/${tipList.city.slug}`,
        id: tipList.city.id,
      });
    }

    crumbs.push({
      name: tipList.title,
      path: `/${tipList.userId}/list/${tipList.slug}`,
      id: tipList.id,
    });
  }

  const handleRemoveTip = async (tip: TipModel) => {
    if (!tipList) return;
    const [, count] = await TipListActions.list(dispatch, {
      limit: 100,
      offset: 0,
      filters: { tipId: tip.id, userIds: auth?.id ? [auth.id] : undefined },
    });

    if (count === 1 && auth?.id === tip.user.id) {
      showConfirmDialog(tip);
    } else {
      TipListActions.removeTips(dispatch, tipList.id, [tip], auth?.id ?? "");
      showNotification({
        title: "Success",
        message: (
          <>
            <strong>
              {i18n.language === "en"
                ? "Successfully " +
                  (auth?.id === tip.user.id ? " removed" : " unborrowed") +
                  " " +
                  tip.title +
                  " from " +
                  tipList.title
                : "Tog bort " +
                  tip.title +
                  " från " +
                  tipList.title +
                  (auth?.id === tip.user.id ? "" : "s") +
                  " lista"}
            </strong>
          </>
        ),
      });
    }
  };

  const middle = () => {
    if (newUser) {
      return <NewUser userId={userId as string} />;
    } else if ((!isPhone || listSlug) && tipList) {
      return (
        <TipList
          tipList={tipList}
          crumbs={crumbs}
          onRemoveTipFromTipList={
            auth?.id === tipList.user.id
              ? (tip) => handleRemoveTip(tip)
              : undefined
          }
        />
      );
    } else if (isPhone && user) {
      return <MobileUserLists user={user} />;
    } else if (loading)
      return (
        <div className={css.LoadingList}>
          <Spinner />
        </div>
      );
    else return <NotFoundView />;
  };

  return (
    <div className={css.Background}>
      {confirmDialog}

      <div className={css.Desktop}>
        <DesktopLayout tipList={tipList}>{middle()}</DesktopLayout>
      </div>
      <div className={css.Phone}>
        <MobileLayout tipList={tipList} listId={listSlug as string}>
          {middle()}
        </MobileLayout>
      </div>
    </div>
  );
}
