import css from "./MobileLayout.module.css";
import { TipListModel } from "Models/TipListModel";
import StartpageHeader from "../StartpageHeader";
import Wiki from "../Wiki";
import Icons from "Components/Icons";
import React, { useState } from "react";
import MobileMapView from "../MobileMapView";
import { useTranslation } from "react-i18next";

const MobileLayout = ({
  tipList,
  children,
  listId,
}: {
  tipList: TipListModel | null | undefined;
  children: JSX.Element;
  listId: string | undefined;
}) => {
  const { t } = useTranslation();
  const [mapMode, setMapMode] = useState(false);
  const togglemapmode = () => {
    setMapMode((prev) => !prev);
  };

  return (
    <article className={css.Content}>
      <StartpageHeader tipList={tipList} />

      {!mapMode && (
        <>
          <section className={css.Main}>
            {React.cloneElement(children, { togglemapmode: togglemapmode })}
          </section>

          {tipList && listId && (
            <>
              <section className={css.Wiki}>
                <Wiki tipList={tipList} />
              </section>
            </>
          )}
        </>
      )}

      {mapMode && <MobileMapView tipList={tipList} />}

      {listId && (
        <div
          className={css.MapButton}
          onClick={() => setMapMode((prev) => !prev)}
        >
          {mapMode ? (
            <>
              {t("list")}
              <Icons.List className={css.MapIcon} />
            </>
          ) : (
            <>
              {t("map")}
              <Icons.MapPainted className={css.MapIcon} />
            </>
          )}
        </div>
      )}
    </article>
  );
};

export default MobileLayout;
