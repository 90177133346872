import { TestimonialModel } from "Models/TestimonialModel";
import { RootState } from "Store";
import UserSelectors from "./UserSelectors";

function get(id: string): (state: RootState) => TestimonialModel | null {
  return (state: RootState) => {
    const testimonial = state.testimonials[id] ?? null;

    if (!testimonial) {
      return testimonial;
    }

    return {
      ...testimonial,
      user: UserSelectors.get(testimonial.user.id)(state) ?? testimonial.user,
    };
  };
}

function list(...ids: string[]): (state: RootState) => TestimonialModel[] {
  return (state: RootState) => {
    const testimonials = ids
      .map((id) => state.testimonials[id])
      .filter((city) => city !== undefined);
    const userIds: string[] = [];

    testimonials.forEach((testimonial) => {
      if (testimonial.user?.id) {
        userIds.push(testimonial.user.id);
      }
    });

    const users = UserSelectors.list(...userIds)(state);

    return testimonials.map((testimonial) => ({
      ...testimonial,
      user:
        users.find((user) => user.id === testimonial.user?.id) ??
        testimonial.user,
    }));
  };
}

export default {
  get,
  list,
};
