import { ModalControllerContext } from "App";
import Icons from "Components/Icons";
import Tooltip from "Components/Tooltip";
import UI from "Components/UI";
import { ButtonProps } from "Components/UI/Button/Button";
import { UserModel } from "Models/UserModel";
import React, { useContext, useState } from "react";
import MiscUtils from "Utils/MiscUtils";
import css from "./ProfileStatistics.module.css";
import { useTranslation } from "react-i18next";

type ToolTipsType = "followedTipList" | "follower" | "borrower";

export default function ProfileStatistics({
  user,
  myProfile,
  className,
  statTitles = false,
}: {
  user: UserModel | null;
  myProfile: boolean;
  className?: string;
  statTitles?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const { borrowersDialog, followedTipListsDialog, followersDialog } =
    useContext(ModalControllerContext);

  const [currentTooltip, setCurrentTooltip] = useState<HTMLInputElement | null>(
    null
  );

  if (!user) return null;

  const onClicks: {
    [Property in ToolTipsType]: (UserModel) => void;
  } = {
    borrower: user.borrowerCount > 0 ? borrowersDialog : () => {},
    followedTipList:
      user.followedTipListCount > 0 ? followedTipListsDialog : () => {},
    follower: user.followerCount > 0 ? followersDialog : () => {},
  };

  const buttonProps: ButtonProps = {
    variant: "text",
    color: "secondary",
    className: css.Button,
    onMouseLeave: () => setCurrentTooltip(null),
    onMouseEnter: (e) =>
      setCurrentTooltip((e.currentTarget as HTMLInputElement) ?? null),
    onClick: (e) =>
      e.currentTarget.dataset.key &&
      onClicks[e.currentTarget.dataset.key](user),
    style: {
      cursor:
        user[`${currentTooltip?.dataset.key}Count`] > 0 ? "pointer" : "default",
    },
  };

  const getRefCoords = (ref: HTMLInputElement) => {
    if (ref != null) {
      const rect = ref.getBoundingClientRect();
      return {
        left: rect.x - 75,
        top: rect.y - 25,
      };
    }
  };

  const toolTipContent: { [Property in ToolTipsType]: string } = {
    followedTipList: myProfile
      ? `${t("i-am-following")} ${user.followedTipListCount} ${
          user.followedTipListCount > 1 ? t("lists") : t("list")
        }`
      : `${user?.firstName} ${t("is-following")} ${MiscUtils.quantify(
          t("list"),
          user.followedTipListCount
        )}`,
    follower: myProfile
      ? t("who-follow-me")
      : `${t("whos-following")} ${MiscUtils.formatNameApos(user.firstName)} ${t(
          "lists"
        )}?`,
    borrower: myProfile
      ? t("who-borrow-me")
      : `${t("whos-borrowing")} ${MiscUtils.formatNameApos(user.firstName)} ${t(
          "tips"
        )}?`,
  };

  return (
    <div className={[css.ProfileButtons, className ?? ""].join(" ")}>
      <UI.Button {...buttonProps} data-key="followedTipList">
        <Icons.Following className={css.ListIcon} />
        <div>
          {user.followedTipListCount} {statTitles && t("following-left-column")}
        </div>
      </UI.Button>

      <UI.Button {...buttonProps} data-key="follower">
        <Icons.Followers className={css.PepoleIcon} />
        <div>
          {statTitles
            ? MiscUtils.quantify(
                t("follower"),
                user.followerCount,
                true,
                i18n.language
              )
            : user.followerCount}
        </div>
      </UI.Button>

      <UI.Button {...buttonProps} data-key="borrower">
        <Icons.StarPainted className={css.Star} />
        <div>
          {user.borrowerCount} {statTitles && t("borrowed")}
        </div>
      </UI.Button>

      {currentTooltip && (
        <Tooltip
          selector={"#tooltip"}
          coord={getRefCoords(currentTooltip)}
          isOn={!!currentTooltip}
        >
          {toolTipContent[currentTooltip.dataset.key as ToolTipsType]}
        </Tooltip>
      )}
    </div>
  );
}
