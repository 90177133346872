import Cards from "Components/Cards";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TestimonialActions from "Store/Actions/TestimonialActions";
import TestimonialSelectors from "Store/Selectors/TestimonialSelectors";

import css from "./Testimonials.module.css";
import { useTranslation } from "react-i18next";

export default function Testimonials(): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [testimonialIds, setTestimonialIds] = React.useState<string[]>([]);
  const testimonials = useSelector(
    TestimonialSelectors.list(...testimonialIds)
  );

  // Get testimonials.
  React.useEffect(() => {
    let mounted = true;

    (async () => {
      const [nextTestimonials] = await TestimonialActions.list(dispatch, {
        limit: 4,
      });

      if (mounted) {
        setTestimonialIds(
          nextTestimonials.map((testimonial) => testimonial.id)
        );
      }
    })();

    return () => {
      mounted = false;
    };
  }, [dispatch]);

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <section className={css.Container}>
      <h4>{t("res-inside-testimonials")}</h4>

      <div className={css.Tiles}>
        {testimonials.map((testimonial) => (
          <div className={css.Tile} key={testimonial.id}>
            <blockquote>{testimonial.content}</blockquote>

            <Cards.User user={testimonial.user} />
          </div>
        ))}
      </div>
    </section>
  );
}
