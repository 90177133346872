import React from "react";
import CityActions from "Store/Actions/CityActions";
import { useDispatch, useSelector } from "react-redux";
import CitySelectors from "Store/Selectors/CitySelectors";
import css from "./PopularDestinations.module.css";
import CardStacked from "Components/Cards/CardStacked";
import { useMediaQuery } from "react-responsive";

type PopularDestinationsProps = {
  className?: string;
  cols?: number;
  title?: string;
  size?: number;
  limit?: number;
};

export default function PopularDestinations({
  className,
  cols = 4,
  size = 137,
  title,
  limit = 12,
}: PopularDestinationsProps): JSX.Element {
  const dispatch = useDispatch();
  const [cityIds, setCityIds] = React.useState<string[]>([]);
  const cities = useSelector(CitySelectors.list(...cityIds));

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });
  // Load popular cities.
  React.useEffect(() => {
    (async () => {
      const [nextCities] = await CityActions.list(dispatch, {
        limit: limit,
        sort: { tipCount: true, tipListCount: true },
      });

      setCityIds(nextCities.map((city) => city.id));
    })();
  }, [dispatch, limit]);

  const leftSide = cities.slice(0, Math.floor(cities.length / 2));
  const rightSide = cities.slice(Math.floor(cities.length / 2));

  return (
    <>
      {isPhone ? (
        <>
          <section className={[css.Container, className ?? ""].join(" ")}>
            {title && <h4>{title}</h4>}

            <div className={css.Tiles}>
              {leftSide.map((city) => (
                <CardStacked key={city.id} value={city} size={size} />
              ))}
            </div>
            <div className={css.Tiles}>
              {rightSide.map((city) => (
                <CardStacked key={city.id} value={city} size={size} />
              ))}
            </div>
          </section>
        </>
      ) : (
        <section className={[css.Container, className ?? ""].join(" ")}>
          {title && <h4>{title}</h4>}

          <div
            className={css.Tiles}
            style={{
              gridTemplateRows: "auto auto",
              gridTemplateColumns: [...Array(cols)].map(() => "1fr").join(" "),
            }}
          >
            {cities.map((city) => (
              <CardStacked key={city.id} value={city} size={size} />
            ))}
          </div>
        </section>
      )}
    </>
  );
}
