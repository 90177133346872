import FeaturedShare from "Components/Icons/FeaturedShare";
import FeaturedCity from "Components/Icons/HomepageCity";
import HomepageShare from "Components/Icons/HomepageShare";
import FeaturedStar from "Components/Icons/HomepageStar";
import UI from "Components/UI";
import { useRouter } from "next/router";
import css from "./AboutHero.module.css";
import { useTranslation } from "react-i18next";

const AboutHero = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const location = useRouter();
  return (
    <article className={[css.Items, className ?? ""].join(" ")}>
      <section className={css.Item}>
        <FeaturedCity />
        <h3>{t("enjoy")}</h3>
        <p>{t("about-hero-enjoy")}</p>
      </section>
      <section className={css.Item}>
        <FeaturedStar />
        <h3>{t("borrow")}</h3>
        <p>{t("about-hero-borrow")}</p>
      </section>

      <section className={css.Item}>
        <HomepageShare />
        <h3>{t("inspired")}</h3>
        <p>{t("about-hero-inspired")}</p>
      </section>
      <UI.Button
        className={css.LearnMore}
        variant="fill"
        onClick={() => location.push("/about")}
      >
        {t("learn-more")}
      </UI.Button>
    </article>
  );
};

export default AboutHero;
