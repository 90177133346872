import { CityModel } from "Models/CityModel";
import Services from "Services";
import { AppDispatch } from "Store";
import { cityUpdated } from "Store/Reducers/CityReducer";

async function get(
  dispatch: AppDispatch,
  id: string
): Promise<CityModel | null> {
  const city = await Services.Location.City.get(id);

  if (city) {
    dispatch(cityUpdated([city]));
  }

  return city;
}

/**
 * List cities.
 * @param dispatch Dispatch function.
 * @param args Listing args.
 */
async function list(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.Location.City.list>
): Promise<[CityModel[], number]> {
  const [cities, total] = await Services.Location.City.list(...args);

  dispatch(cityUpdated(cities));

  return [cities, total];
}

export default {
  get,
  list,
};
