import List from "Components/Icons/List";
import ListItem from "Components/ListItem";
import Button from "Components/UI/Button";
import Spinner from "Components/UI/Spinner";
import { TipListModel } from "Models/TipListModel";
import Services from "Services";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import commonCss from "../Common.module.css";

type TipListClicked = {
  tipLists: TipListModel[];
  showCity: boolean;
  showImage: boolean;
  highlight?: boolean;
};

function TipListClicked({
  tipLists,
  showCity,
  showImage,
  highlight,
}: TipListClicked): JSX.Element {
  const router = useRouter();
  const highlightId = highlight && (router.query.listId as string);

  return (
    <>
      {tipLists.map((tipList) => (
        <React.Fragment key={tipList.id}>
          <Link href={`/${tipList.user.slug}/list/${tipList.slug}`}>
            <a>
              <div
                className={
                  highlightId === tipList.slug ? commonCss.Highlight : undefined
                }
              >
                <ListItem
                  value={tipList}
                  showImage={showImage}
                  showCity={showCity}
                />
              </div>
            </a>
          </Link>
        </React.Fragment>
      ))}
    </>
  );
}

type TipListProps = {
  className?: string;
  title: string | ((total: number) => string);
  fetchCriterion?: Parameters<typeof Services.TipList.list>[0];
  showCity?: boolean;
  showImage?: boolean;
  highlight?: boolean;
  hideIcon?: boolean;
  initialVisible?: number;
};

export default function TipList({
  title,
  className,
  fetchCriterion,
  showCity = false,
  showImage = false,
  highlight = true,
  hideIcon = false,
  initialVisible = 3,
}: TipListProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tipListIds, setTipListIds] = React.useState<string[] | null>(null);
  const tipLists = useSelector(TipListSelectors.list(...(tipListIds ?? [])));

  const [total, setTotal] = React.useState(0);
  const [visibleResults, setVisibleResults] = useState(initialVisible);

  const loadMore = React.useMemo(() => {
    return Services.TipList.getLoadMoreFunc(
      ([nextTipLists, nextTotal]) => {
        setTipListIds(nextTipLists.map((tipList) => tipList.id));
        setTotal(nextTotal);
      },
      dispatch,
      { ...(fetchCriterion ?? {}), limit: 2 * initialVisible }
    );
  }, [dispatch, fetchCriterion, initialVisible]);

  // Initial load
  React.useEffect(() => {
    loadMore();
  }, [fetchCriterion]);

  return (
    <section className={[commonCss.Container, className ?? ""].join(" ")}>
      <div
        className={[
          commonCss.TitleContainer,
          hideIcon && commonCss.HideIcon,
        ].join(" ")}
      >
        {!hideIcon && <List className={commonCss.Icon} />}
        <span className={commonCss.Title}>
          {typeof title === "string" ? title : title(total)}
        </span>
      </div>

      <div className={commonCss.Items}>
        {tipListIds === null && (
          <div className={commonCss.TextNolist}>
            <Spinner />
          </div>
        )}
        {tipListIds && tipLists.length === 0 && (
          <div className={commonCss.TextNolist}>{t("no-lists")}</div>
        )}
        <TipListClicked
          tipLists={tipLists.slice(0, visibleResults)}
          showCity={showCity}
          showImage={showImage}
          highlight={highlight}
        />
      </div>
      {visibleResults < (tipLists?.length ?? 0) && (
        <Button
          className={commonCss.ShowMore}
          variant="text"
          color="pink"
          onClick={() => {
            loadMore();
            setVisibleResults((prev) =>
              Math.min(prev + initialVisible, tipLists.length ?? 0)
            );
          }}
        >
          {t("show-more")}
        </Button>
      )}
    </section>
  );
}
