import Link from "next/link";
import React from "react";
import css from "./BreadCrumbs.module.css";

type BreadCrumbsProps = {
  className?: string;
  crumbs: { name: string; path: string; id: string }[];
};

export default function BreadCrumbs({
  crumbs,
  className,
}: BreadCrumbsProps): JSX.Element | null {
  if (crumbs.length === 0) {
    return null;
  }

  return (
    <div className={[css.Container, className ?? ""].join(" ")}>
      {crumbs.map((crumb) => (
        <div key={crumb.id}>
          <Link href={`${crumb.path}`}>{crumb.name}</Link>
          <span>›</span>
        </div>
      ))}
    </div>
  );
}
