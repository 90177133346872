import { TestimonialModel } from "Models/TestimonialModel";
import Services from "Services";
import { AppDispatch } from "Store";
import { testimonialUpdated } from "Store/Reducers/TestimonialReducer";

/**
 * Get a testimonial.
 * @param dispatch Dispatch function.
 * @param id Testimonial identifier.
 */
async function get(
  dispatch: AppDispatch,
  id: string
): Promise<TestimonialModel | null> {
  const testimonial = await Services.Testimonial.get(id);

  if (testimonial) {
    dispatch(testimonialUpdated([testimonial]));
  }

  return testimonial;
}

/**
 * List testimonials.
 * @param dispatch Dispatch function.
 * @param args Listing args.
 */
async function list(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.Testimonial.list>
): Promise<[TestimonialModel[], number]> {
  const [testimonials, total] = await Services.Testimonial.list(...args);

  dispatch(testimonialUpdated(testimonials));

  return [testimonials, total];
}

export default {
  get,
  list,
};
