import React from "react";
import globalCss from "Global.module.css";
import Services from "Services";
import css from "./VerifySignUp.module.css";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "react-i18next";

export default function VerifySignUp(): JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();
  const code = router.query.code as string;
  const [success, setSuccess] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [message, setMessage] = React.useState<string>();

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      setPending(true);

      try {
        await Services.Auth.verify(code);

        if (mounted) {
          setSuccess(true);
          setMessage(t("welcome-to-wl"));
        }
      } catch (err: any) {
        if (mounted) {
          setSuccess(false);
          setMessage(err.message);
        }
      } finally {
        if (mounted) {
          setPending(false);
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [code]);

  return (
    <div className={[globalCss.Content, css.Container].join(" ")}>
      <h4>
        {pending && t("verifying")}

        {!pending && success ? t("successfully-verified") : t("failed-verify")}
      </h4>

      <p>
        {pending && t("please-wait")}
        {!pending && message}
      </p>
    </div>
  );
}
