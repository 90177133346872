import css from "./DesktopLayout.module.css";
import { TipListModel } from "Models/TipListModel";
import React from "react";
import UserLeftColumn from "Views/Common/UserLeftColumn";
import Wiki from "../Wiki";
import { useMediaQuery } from "react-responsive";

const DesktopLayout = ({
  tipList,
  children,
}: {
  tipList: TipListModel | null | undefined;
  children: React.ReactNode;
}) => {
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  if (isPhone) {
    return null;
  }

  return (
    <article className={css.Content}>
      <div className={css.UserColumn}>{tipList && <UserLeftColumn />}</div>
      <section className={css.Main}>{children}</section>
      <div className={css.Wiki}>{tipList && <Wiki tipList={tipList} />}</div>
    </article>
  );
};

export default DesktopLayout;
