import HomepageCity from "Components/Icons/HomepageCity";
import HomepageShare from "Components/Icons/HomepageShare";
import HomepageStar from "Components/Icons/HomepageStar";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import css from "./MobileInfo.module.css";
import Icons from "Components/Icons";

export default function MobileInfo(): JSX.Element | null {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const copy = [
    {
      text: t("enjoy"),
      icon: <HomepageCity />,
    },
    {
      text: t("borrow"),
      icon: <HomepageStar />,
    },
    {
      text: t("inspired"),
      icon: <HomepageShare />,
    },
  ];

  let start = useRef(0);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    start.current = e.changedTouches[0].clientX;
  };

  const onTouchEnd = (e) => {
    const distance = e.changedTouches[0].clientX - start.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isRightSwipe) setIndex((prev) => Math.min(prev + 1, 2));
    if (isLeftSwipe) setIndex((prev) => Math.max(prev - 1, 0));
  };

  return (
    <section className={css.Container}>
      <section className={css.RESIcon}>
        <Icons.RESIcon />
      </section>
      <section
        className={css.Item}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        {copy[index].icon}
        <h4>{copy[index].text}</h4>
        <div className={css.ColumnCircles}>
          {copy.map((_, idx) => (
            <div
              className={idx === index ? css.DarkCirkel : css.LightCirkel}
              key={idx}
            />
          ))}
        </div>
      </section>
    </section>
  );
}
