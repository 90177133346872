import ListItem from "Components/ListItem";
import { TipListModel } from "Models/TipListModel";
import { UserModel } from "Models/UserModel";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TipListActions from "Store/Actions/TipListActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipActions from "Store/Actions/TipActions";
import css from "./MobileUserLists.module.css";
import TipSelectors from "Store/Selectors/TipSelectors";
import { TipModel } from "Models/TipModel";
import { ModalControllerContext } from "App";

const MobileUserLists = ({ user }: { user: UserModel }) => {
  const { tipDialog } = React.useContext(ModalControllerContext);
  const dispatch = useDispatch();
  const location = useRouter();

  const [lists, setLists] = useState<null | TipListModel[]>(null);
  const [followingLists, setFollowingLists] = useState<null | TipListModel[]>(
    null
  );

  const [draftLists, setDraftLists] = useState<null | TipModel[]>(null);

  const [checkedCategory, setCheckedCategory] = useState<
    "Lists" | "Following" | "Drafts"
  >("Lists");

  const tips = useSelector(TipSelectors.drafts);
  const tipCount = tips.length;
  const auth = useSelector(AuthSelectors.get);
  const authId = auth?.id;

  const myProfile = user?.id === auth?.id;

  useEffect(() => {
    (async () => {
      const lists = await TipListActions.list(dispatch, {
        filters: { userIds: [user.id] },
        sort: { followerCount: true },
        limit: 99,
      });

      setLists(lists[0]);
    })();

    (async () => {
      const following = await TipListActions.list(dispatch, {
        filters: { followerUserIds: [user.id] },
        sort: { followerCount: true },
        limit: 99,
      });

      setFollowingLists(following[0]);
    })();

    if (!authId) {
      return;
    }

    (async () => {
      const drafts = await TipActions.list(dispatch, {
        limit: 100,
        filters: { userIds: [authId], draft: true },
      });

      setDraftLists(drafts[0]);
    })();
  }, [dispatch, user.id]);

  const CategoryTitles = {
    Lists: myProfile ? "My Lists" : user.firstName + "Lists",
    Following: "Following Lists",
    Drafts: "My Drafts",
  };

  const getShowLists = () => {
    if (checkedCategory == "Lists") {
      return lists;
    }
    if (checkedCategory == "Following") {
      return followingLists;
    }
    if (checkedCategory == "Drafts") {
      return draftLists;
    }
  };

  const showLists = getShowLists();

  return (
    <article className={css.Container}>
      <div className={css.Filters}>
        {(myProfile
          ? ["Lists", "Following", "Drafts"]
          : ["Lists", "Following"]
        ).map((category) => (
          <div
            className={[
              css.FiltersButton,
              checkedCategory === category && css.FilterSelected,
            ].join(" ")}
            key={category}
          >
            <div
              onKeyDown={() => {}}
              role="button"
              tabIndex={-1}
              onClick={() => {
                setCheckedCategory(category as typeof checkedCategory);
              }}
            >
              <input
                type="checkbox"
                checked={checkedCategory === category}
                readOnly
              />

              <span>{CategoryTitles[category]}</span>
              {tipCount !== 0 && CategoryTitles[category] === "My Drafts" && (
                <span className={css.DraftIcon}>
                  {tipCount > 99 ? "99+" : tipCount}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      <article className={css.Lists}>
        {showLists?.map((list) => (
          <div
            key={list.id}
            onClick={() => (
              checkedCategory !== "Drafts"
                ? location.push(`/${list.user.slug}/list/${list.slug}`)
                : tipDialog(list),
              location.push(`/${list.user.slug}/list/${list.slug}`)
            )}
          >
            <ListItem value={list} />
          </div>
        ))}
      </article>
    </article>
  );
};

export default MobileUserLists;
