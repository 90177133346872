import React, { ReactNode } from "react";
import css from "./AboutCard.module.css";

type AboutCardProps = {
  icon: any;
  title: string;
  text: string;
  children?: ReactNode;
  className?: string;
};

export default function AboutCard({
  icon,
  title,
  text,
  children = null,
  className,
}: AboutCardProps): JSX.Element {
  return (
    <div
      className={[css.Container, className ? className : css.Default].join(" ")}
    >
      <div className={css.Icon}>{icon}</div>
      <h4>{title}</h4>
      <p>{text}</p>
      {children}
    </div>
  );
}
