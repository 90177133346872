import React from "react";
import Hero from "Components/Hero";
import MainSearch from "Components/MainSearch";
import heroImage from "Assets/Images/Heroes/home.jpg";
import globalCss from "Global.module.css";
import { useMediaQuery } from "react-responsive";
import css from "./HomeView.module.css";
import Testimonials from "./Components/Testimonials";
import { Footer } from "Partials";
import PopularDestinations from "Components/PopularDestinations";
import AboutHero from "Views/Common/AboutHero";
import HomepageCity from "Components/Icons/HomepageCity";
import HomepageStar from "Components/Icons/HomepageStar";
import HomepageShare from "Components/Icons/HomepageShare";
import MobileInfo from "./Components/MobileInfo";
import { useTranslation } from "react-i18next";

export default function HomeView(): JSX.Element {
  const { t } = useTranslation();
  const [expandedCheck, setExpandedCheck] = React.useState(false);
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const isHalf = useMediaQuery({
    query: "(max-width: 1400px)",
  });

  const getHeroHeight = () => {
    if (isPhone) {
      if (expandedCheck) {
        return 0;
      }
      return 375;
    }
    return 290;
  };

  const showSection = () => {
    if (expandedCheck && isPhone) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Hero image={heroImage} height={getHeroHeight()} />
      <div className={globalCss.Content}>
        <section className={css.Header} style={{ height: getHeroHeight() }}>
          <section className={css.Items}>
            {!isPhone ? (
              <>
                <section className={css.Item}>
                  <HomepageCity />
                  {t("enjoy")}
                </section>
                <section className={css.Item}>
                  <HomepageStar />
                  {t("borrow")}
                </section>

                <section className={css.Item}>
                  <HomepageShare />
                  {t("inspired")}
                </section>
              </>
            ) : (
              <>{!expandedCheck && <MobileInfo />}</>
            )}
          </section>
          <div>
            <MainSearch
              setExpandedCheck={setExpandedCheck}
              placeholder={t("home-search")}
              className={css.MainSearch}
              wrapperClassName={css.MainSearchWrapper}
              inputClassName={css.MainSearchInput}
            />
          </div>
        </section>
        {showSection() && (
          <section className={css.Content}>
            <PopularDestinations
              title={t("home-popular")}
              cols={isHalf ? 5 : 6}
              size={isHalf ? 140 : 200}
              limit={isHalf ? 10 : 12}
            />
          </section>
        )}
      </div>
      {showSection() && (
        <>
          <AboutHero className={css.About} />
          <Testimonials />
          <Footer />
        </>
      )}
    </>
  );
}
