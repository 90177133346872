import React from "react";
import css from "./Hero.module.css";

type HeroProps = {
  height?: number;
  image: string;
  opacity?: number;
  backgroundColor?: string;
};

export default function Hero({
  image,
  height = 420,
  opacity = 1,
  backgroundColor = "#000000",
}: HeroProps): JSX.Element {
  return (
    <section className={css.Container}>
      <div className={css.BackDrop} style={{ height, backgroundColor }}>
        <div className={css.Overlay} />
        <img src={image} alt="Hero" style={{ opacity }} />
      </div>
    </section>
  );
}
