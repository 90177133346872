/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Icons from "Components/Icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useModal } from "Components/UI/Modal";
import { BookableTipsResponse, TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import { ModalControllerContext, NotificationContext } from "App";
import Services from "Services";
import { useDispatch, useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipListActions from "Store/Actions/TipListActions";
import { UserModel } from "Models/UserModel";
import Cards from "Components/Cards";
import UI from "Components/UI";
import css from "./TipList.module.css";
import Link from "next/link";
import { useRouter } from "next/dist/client/router";
import BreadCrumbs from "Components/BreadCrumbs";
import GetMarkerEncoded from "Components/Icons/Category/MapCategory";
import { useMediaQuery } from "react-responsive";
import TipActions from "Store/Actions/TipActions";
import TipSelectors from "Store/Selectors/TipSelectors";
import ListItem from "Components/ListItem";
import CategoryFilter from "Components/CategoryFilter";
import { useTranslation } from "react-i18next";

type TipListProps = {
  tipList: TipListModel;
  crumbs?: any;
  onRemoveTipFromTipList?: (tip: TipModel) => void;
  togglemapmode?: () => void;
};

function TipsList({
  tipList,
  crumbs,
  onRemoveTipFromTipList,
  togglemapmode,
}: TipListProps): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tipListDialog, signInDialog, tipDialog, mapDialog } = useContext(
    ModalControllerContext
  );
  const auth = useSelector(AuthSelectors.get);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const location = useRouter();
  const [map, setMap] = useState<google.maps.Map>();
  const markers = useRef<{ marker: google.maps.Marker; categoryId: string }[]>(
    []
  );
  const [selectedTip, setSelectedTip] = useState<TipModel | undefined>(
    undefined
  );
  const [checkedCategory, setCheckedCategory] = useState<string | null>(null);
  const showNotification = useContext(NotificationContext);
  const [tipIds, setTipIds] = useState<string[] | null>(null);
  const [bookableTips, setBookableTips] = useState<BookableTipsResponse | null>(
    null
  );
  const tips = useSelector(TipSelectors.list(...(tipIds ?? [])));
  const { tipId } = location.query;

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  useEffect(() => {
    (async () => {
      setTipIds(null);

      const [nextTips] = await TipActions.list(dispatch, {
        limit: 100,
        filters: { tipListIds: [tipList.id] },
      });

      setTipIds(nextTips.map((tip) => tip.id));
      const bookableTips = await TipListActions.getBookableTips(tipList.id);
      setBookableTips(bookableTips);
    })();
  }, [dispatch, tipList.id]);

  useEffect(() => {
    if (tips.length !== 0 && (tipId as string) !== selectedTip?.slug) {
      setSelectedTip(tips.find((tip) => tip.slug === tipId) || undefined);
    }
  }, [tips, tipId]);

  const [deleteDialog, showDeleteDialog, hideDeleteDialog] = useModal(
    <UI.ConfirmDialog
      message={t("confirm-delete-list")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideDeleteDialog()}
      onConfirm={async () => {
        if (!tipList) {
          return;
        }

        try {
          TipListActions.remove(dispatch, tipList);

          await hideDeleteDialog();
          showNotification({
            title: t("list-deleted"),
            type: "error",
          });
          location.push(`/${auth?.slug}`);
        } catch (err) {
          showNotification({ title: t("something-went-wrong"), type: "error" });
          hideDeleteDialog();
        }
      }}
    />
  );

  const cityLat = tipList?.city.latitude;
  const cityLng = tipList?.city.longitude;

  // Initiate the map.
  React.useEffect(() => {
    if (isPhone) return;
    (async () => {
      if (
        !mapContainerRef.current ||
        typeof cityLat !== "number" ||
        typeof cityLng !== "number"
      ) {
        return;
      }

      try {
        const map = await Services.GoogleMaps.getMap(mapContainerRef.current, {
          lat: cityLat,
          lng: cityLng,
        });
        setMap(map);
      } catch {}
    })();
  }, [cityLat, cityLng]);

  // Place markers for the tips.
  React.useEffect(() => {
    if (!map) {
      return;
    }

    const oldMarkers = markers.current;
    const latLngs: { lat: number; lng: number }[] = [];

    // Set new markers.
    markers.current = tips
      .filter((tip) => tip.categoryId === checkedCategory || !checkedCategory)
      .map((tip) => {
        const { longitude: lng, latitude: lat } = tip.venue ?? {};

        if (typeof lng !== "number" || typeof lat !== "number") {
          return undefined;
        }

        latLngs.push({ lat, lng });

        const marker = new google.maps.Marker({
          map,
          position: { lat, lng },
          icon: GetMarkerEncoded(tip.category?.icon ?? 0, false),
          title: tip.venue?.name,
        });

        marker.addListener("click", () => {
          if (tipList?.id) {
            location.push({
              pathname: `/${tipList.userId}/list/${tipList.slug}`,
              query: { tipId: tip.slug },
            });
          }
        });

        return {
          marker,
          categoryId: tip.categoryId,
        };
      })
      .filter((marker) => marker !== undefined) as {
      marker: google.maps.Marker;
      categoryId: string;
    }[];

    // Remove old markers:
    setTimeout(() => {
      // Avoid flickering.
      oldMarkers.forEach((marker) => {
        marker.marker.setMap(null);
      });
    }, 100);

    if (latLngs.length === 0) {
      return;
    }

    // Fit map.
    const latLngBounds = new google.maps.LatLngBounds(
      {
        lat: Math.min(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.min(...latLngs.map((latLng) => latLng.lng)),
      },
      {
        lat: Math.max(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.max(...latLngs.map((latLng) => latLng.lng)),
      }
    );

    map.fitBounds(latLngBounds);
  }, [tips, map, tipList?.id, location]);

  // Follow tip list.
  const followTipList = React.useCallback(
    async (nextUser: UserModel | null, nextTipList: TipListModel) => {
      if (!nextUser) {
        // Show sign in dialog.
        signInDialog();
        return;
      }

      TipListActions.toggleFollowing(dispatch, nextUser, nextTipList);

      showNotification({
        title: t("success"),
        message: (
          <>
            <strong>
              {!nextTipList.following ? t("following") : t("unfollowed")}
            </strong>
            <Link href={`/${nextTipList.user.slug}/list/${nextTipList.slug}`}>
              <a>{nextTipList.title}</a>
            </Link>
          </>
        ),
        type: !nextTipList.following ? "neutral" : "alert",
      });
    },
    [dispatch, signInDialog, showNotification]
  );

  return (
    <div className={css.Container}>
      {deleteDialog}

      {!isPhone && (
        <article>
          <div className={css.Info}>
            <div className={css.Content}>
              <h4>
                {crumbs ? <BreadCrumbs crumbs={crumbs} /> : tipList.title}
              </h4>
            </div>

            <div className={css.Actions}>
              <div className={css.AlignButtons}>
                <UI.Button
                  color="white"
                  onClick={() => mapDialog(tipList, tips, selectedTip)}
                >
                  <div className={css.IconButton}>
                    <Icons.Map className={css.MarginMapIcon} />
                    {t("map")}
                  </div>
                </UI.Button>
                <UI.Button
                  color="white"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/${tipList.user.slug}/list/${tipList.slug}`
                    );

                    showNotification({
                      title: t("tiplist-copied-clipboard"),
                      type: "neutral",
                    });
                  }}
                >
                  <div className={css.IconButton}>
                    <Icons.Share className={css.ShareIcon} />
                    {t("share")}
                  </div>
                </UI.Button>
                {auth?.id !== tipList.user.id && (
                  <UI.Button
                    color="white"
                    onClick={() => followTipList(auth, tipList)}
                  >
                    <div className={css.IconButton}>
                      <Icons.Following className={css.MarginFollowIcon} />
                      {tipList.following ? t("unfollow") : t("follow")}
                    </div>
                  </UI.Button>
                )}
                {auth && auth.id === tipList.user.id && (
                  <>
                    <button
                      color="white"
                      className={css.AddButton}
                      onClick={() => tipDialog(undefined, "", tipList)}
                    >
                      <Icons.Add
                        className={css.MarginAddIcon}
                        stroke={"var(--color-grey700)"}
                      />
                      {t("tip")}
                    </button>

                    <UI.ContextMenu
                      button={
                        <UI.Button color="white" className={css.ListOptions}>
                          <div className={css.InLine}>
                            <Icons.Menu className={css.OptionsIcon} />
                          </div>
                        </UI.Button>
                      }
                    >
                      <h4 className={css.OptionsHeader}>List Options</h4>

                      <UI.Button
                        variant="text"
                        color="white"
                        onClick={() => tipListDialog(tipList)}
                        className={css.EditButton}
                      >
                        <Icons.EditThin className={css.MarginEditIcon} />
                        {t("edit")}
                      </UI.Button>
                      <UI.Button
                        variant="text"
                        color="delete"
                        onClick={showDeleteDialog}
                        className={css.DeleteButton}
                      >
                        <Icons.Remove className={css.MarginMapIcon} />
                        {t("remove")}
                      </UI.Button>
                    </UI.ContextMenu>
                  </>
                )}
              </div>
            </div>
          </div>
        </article>
      )}
      <div className={css.Scroll}>
        <article>
          <section
            onClick={() => location.replace(`/${tipList.user.slug}`)}
            className={css.UserItemContainer}
          >
            <ListItem
              showSubtitle={false}
              value={tipList.user}
              className={css.UserItem}
            />
          </section>
          <div ref={mapContainerRef} className={css.Map} />
          {/* Category filtering */}
          <div className={css.Filters}>
            <CategoryFilter
              tips={tips}
              callback={(category) => {
                setCheckedCategory(category);
                markers.current.forEach((marker) =>
                  marker.marker.setVisible(
                    marker.categoryId === category || category === null
                  )
                );
              }}
            />
          </div>
          {tips.length === 0 && tipIds != null && tipList.userId === auth?.id && (
            <div className={css.NoTips}>
              <div className={css.Hstack}>
                <Icons.Tip />
                <div className={css.Vstack}>
                  <div className={css.TextChead}>{t("empty-list")}</div>
                  <p className={css.TextC}>{t("create-or-borrow")}</p>

                  <div className={css.CreateTipNo}>
                    <UI.Button
                      className={css.AddTipsButton}
                      onClick={() => tipDialog(undefined, "", tipList)}
                    >
                      {t("create-tip")}
                    </UI.Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTip &&
            (!checkedCategory ||
              checkedCategory === selectedTip.categoryId) && (
              <Cards.Tip
                key={selectedTip.slug}
                className={[css.Selected, css.Tip].join(" ")}
                value={selectedTip}
                onRemoveTipFromTipList={onRemoveTipFromTipList}
                onClickMap={
                  isPhone
                    ? () => {
                        togglemapmode && togglemapmode();
                        location.push(location, {
                          query: { tipId: selectedTip.slug },
                        });
                      }
                    : () => mapDialog(tipList, tips, selectedTip)
                }
                tipList={tipList}
                bookingLink={
                  bookableTips?.find((tip) => tip.tipId === selectedTip.id)
                    ?.naturalReservationUrl
                }
              />
            )}
          {tips.map((tip) => {
            if (
              tip.id === selectedTip?.id ||
              (checkedCategory && checkedCategory !== tip.categoryId)
            ) {
              return null;
            }

            return (
              <Cards.Tip
                value={tip}
                key={tip.id}
                className={css.Tip}
                onRemoveTipFromTipList={onRemoveTipFromTipList}
                onClickMap={
                  isPhone
                    ? () => {
                        togglemapmode && togglemapmode();
                        location.push(location, { query: { tipId: tip.slug } });
                      }
                    : () => mapDialog(tipList, tips, tip)
                }
                tipList={tipList}
                bookingLink={
                  bookableTips?.find(
                    (bookableTip) => bookableTip.tipId === tip.id
                  )?.naturalReservationUrl
                }
              />
            );
          })}
        </article>
      </div>
    </div>
  );
}

export default React.memo(TipsList, (prevProps, nextProps) => {
  return prevProps.tipList.id === nextProps.tipList.id;
});
