import React, { ReactNode } from "react";
import css from "./MobileAboutCard.module.css";

type AboutCardProps = {
  icon: any;
  title: string;
  text: string;
  children?: ReactNode;
  className?: string;
  extraPadding?: boolean;
};

export default function MobileAboutCard({
  icon,
  title,
  text,
  children = null,
  className,
  extraPadding,
}: AboutCardProps): JSX.Element {
  return (
    <div
      className={[css.Container, className ? className : css.Default].join(" ")}
    >
      <div className={css.IconRow}>
        <div
          className={css.Icon}
          style={{ marginTop: extraPadding ? "6px" : "0px" }}
        >
          {icon}
        </div>
        <div className={css.ContentWrapper}>
          <h4 className={css.Title}>{title}</h4>
          <p>{text}</p>
          {children}
        </div>
      </div>
    </div>
  );
}
