import Explorers from "Components/Explorers";
import { TipListModel } from "Models/TipListModel";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Services from "Services";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import MiscUtils from "Utils/MiscUtils";

import css from "./Wiki.module.css";
import { useTranslation } from "react-i18next";

function Wiki({ tipList }: { tipList: TipListModel }): JSX.Element {
  const { t, i18n } = useTranslation();
  const auth = useSelector(AuthSelectors.get);
  const { city, user } = tipList;

  const userId = user.id;
  const cityId = city.id;

  const tipListCriterion: Parameters<typeof Services.TipList.list>[0] = {
    universeId: userId,
    filters: {
      cityIds: [cityId],
    },
    sort: { followerCount: true },
  };

  const tipCriterion: Parameters<typeof Services.Tip.list>[0] = {
    universeId: userId,
    filters: {
      cityIds: [cityId],
    },
    sort: { borrowerCount: true },
    withTipListId: true,
  };

  const tipListCriterionAllCity: Parameters<typeof Services.TipList.list>[0] = {
    filters: {
      cityIds: [cityId],
    },
    sort: { followerCount: true },
  };
  const tipCriterionAllCity: Parameters<typeof Services.Tip.list>[0] = {
    filters: {
      cityIds: [cityId],
    },
    sort: { borrowerCount: true },
    withTipListId: true,
  };

  const hideImage = useMediaQuery({
    query: "(max-width: 1049px)",
  });

  const universeTitle =
    auth?.id === user?.id
      ? t("explore-my-universe")
      : `${t("explore")} ${
          user?.firstName
            ? `${MiscUtils.formatNameApos(user.firstName, i18n.language)} `
            : ""
        }${t("universe").toLowerCase()}`;

  return (
    <section className={css.Container}>
      {
        <div className={css.Universe}>
          <h3 className={[css.Title, css.UniverseTitle].join(" ")}>
            {universeTitle}
          </h3>
          <Explorers.TipList
            title={`${t("lists-with-tips")} ` + (city?.name ?? "")}
            className={css.TipLists}
            fetchCriterion={tipListCriterion}
            showImage={hideImage ? false : true}
            highlight={false}
            initialVisible={4}
          />

          <Explorers.Tip
            title={`${t("tips-in")} ` + (city?.name ?? "")}
            className={css.TipLists}
            fetchCriterion={tipCriterion}
            initialVisible={4}
            showImage={hideImage ? false : true}
          />
        </div>
      }

      <div className={css.Popular}>
        <h3 className={[css.Title, css.PopularTitle].join(" ")}>
          {t("explore-res-inside")}
        </h3>
        <Explorers.TipList
          title={`${t("lists-with-tips")} ` + (city?.name ?? "")}
          className={css.TipLists}
          fetchCriterion={tipListCriterionAllCity}
          highlight={false}
          showImage={hideImage ? false : true}
          initialVisible={4}
        />

        <Explorers.Tip
          title={`${t("tips-in")} ` + (city?.name ?? "")}
          className={css.TipLists}
          fetchCriterion={tipCriterionAllCity}
          initialVisible={4}
          showImage={hideImage ? false : true}
        />
      </div>
    </section>
  );
}

export default memo(Wiki, (prevProps, newProps) => {
  if (prevProps.tipList.id !== newProps.tipList.id) {
    return false;
  } else {
    return true;
  }
});
