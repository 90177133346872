import Icons from "Components/Icons";
import { useRouter } from "next/dist/client/router";
import React from "react";
import css from "./MobileHeader.module.css";

type MobileHeaderProps = {
  title: string;
  ClickItem?: any;
};

const MobileHeader = ({ title, ClickItem = false }: MobileHeaderProps) => {
  const location = useRouter();

  return (
    <article className={css.Content}>
      <section className={css.Navigation}>
        <div
          onClick={() => {
            !ClickItem ? location.back() : ClickItem(false);
          }}
        >
          <Icons.BackArrow />
        </div>
        <div className={css.Title}>{title}</div>
      </section>
    </article>
  );
};

export default MobileHeader;
