import React, { useState } from "react";
import Link from "next/link";
import Services from "Services";
import { useDispatch, useSelector } from "react-redux";
import TipSelectors from "Store/Selectors/TipSelectors";
import commonCss from "../Common.module.css";
import TipIcon from "Components/Icons/Tip";
import ListItem from "Components/ListItem";
import Button from "Components/UI/Button";
import Spinner from "Components/UI/Spinner";
import { useTranslation } from "react-i18next";

type TipProps = {
  className?: string;
  title: string | ((total: number) => string);
  fetchCriterion?: Parameters<typeof Services.Tip.list>[0];
  initialVisible?: number;
  showImage?: boolean;
};

export default function Tip({
  title,
  className,
  fetchCriterion,
  initialVisible = 4,
  showImage = true,
}: TipProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tipIds, setTipIds] = React.useState<string[] | null>(null);
  const tips = useSelector(TipSelectors.list(...(tipIds ?? [])));
  const [total, setTotal] = React.useState(0);
  const [visibleResults, setVisibleResults] = useState(initialVisible);
  const loadMore = React.useMemo(() => {
    return Services.Tip.getLoadMoreFunc(
      ([nextTips, nextTotal]) => {
        setTipIds(nextTips.map((tip) => tip.id));
        setTotal(nextTotal);
      },
      dispatch,
      { ...(fetchCriterion ?? {}), limit: 2 * initialVisible }
    );
  }, [dispatch, fetchCriterion, initialVisible]);

  // Initial load
  React.useEffect(() => {
    loadMore();
  }, [fetchCriterion]);

  return (
    <section className={[commonCss.Container, className ?? ""].join(" ")}>
      <div className={commonCss.TitleContainer}>
        <TipIcon className={commonCss.Icon} />
        <span className={commonCss.Title}>
          {typeof title === "string" ? title : title(total)}
        </span>
      </div>

      <div className={commonCss.Items}>
        {tipIds === null && (
          <div className={commonCss.TextNolist}>
            <Spinner />
          </div>
        )}
        {tipIds && tips.length === 0 && (
          <span className={commonCss.NoMargin}>{t("no-tips")}</span>
        )}
        {tips.slice(0, visibleResults).map((tip) => (
          <React.Fragment key={tip.id}>
            <Link
              href={`/${tip.user.slug}/list/${tip.tipListSlug}?tipId=${tip.slug}`}
              key={tip.id}
            >
              <a>
                <div>
                  <ListItem value={tip} showImage={showImage} />
                </div>
              </a>
            </Link>
          </React.Fragment>
        ))}
      </div>
      {visibleResults < (tips?.length ?? 0) && (
        <Button
          className={commonCss.ShowMore}
          variant="text"
          color="pink"
          onClick={() => {
            loadMore();
            setVisibleResults((prev) =>
              Math.min(prev + initialVisible, tips.length ?? 0)
            );
          }}
        >
          {t("show-more")}
        </Button>
      )}
    </section>
  );
}
