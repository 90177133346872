import React from "react";
import Hero from "Components/Hero";
import globalCss from "Global.module.css";
import heroImage from "Assets/Images/Heroes/about.jpg";
import css from "./AboutView.module.css";
import AboutCard from "Components/Cards/AboutCard";
import TipFilled from "Components/Icons/TipFilled";
import StarFilled from "Components/Icons/StarFilled";
import ListCheckFilled from "Components/Icons/ListCheckFilled";
import GeometryFilled from "Components/Icons/GeometryFilled";
import AboutHero from "Views/Common/AboutHero";
import { Footer } from "Partials";
import LogoIconFilled from "Components/Icons/LogoIconFilled";
import GlobeFilled from "Components/Icons/GlobeFilled";
import CompassFilled from "Components/Icons/CompassFilled";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "./MobileHeader";
import { useTranslation } from "react-i18next";

export default function AboutView(): JSX.Element {
  const { t } = useTranslation();
  const heroHeight = 430;
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  return (
    <>
      <Hero image={heroImage} height={heroHeight} />
      {isPhone ? (
        <MobileHeader title={t("about")} />
      ) : (
        <div className={globalCss.Content}>
          <section className={css.HeroTitle} style={{ height: heroHeight }}>
            <AboutHero className={css.AboutHero} />
          </section>
        </div>
      )}

      <section className={css.Content}>
        <section className={css.Header}>
          {!isPhone && <h1>{t("about-res-inside")}</h1>}
          {!isPhone && <h4>{t("about-res-inside-text")}</h4>}
        </section>

        <div className={css.CardGrid}>
          {isPhone && <h1>{t("about-res-inside")}</h1>}
          <AboutCard
            className={css.AboutCardStyle}
            icon={<LogoIconFilled />}
            title={t("get-started")}
            text=""
          >
            <span className={css.Started}>{t("get-started-text")}</span>
          </AboutCard>
          <AboutCard
            className={css.AboutCardStyle}
            icon={<GeometryFilled />}
            title={t("categories")}
            text=""
          >
            <span className={css.AboutColor}>{t("categories-short")}</span>
            <br />
            <ul>
              <li>{t("hotels")}</li>
              <li>{t("restaurants")}</li>
              <li>{t("night-life")}</li>
              <li>{t("shopping")}</li>
              <li>{t("dos")}</li>
              <li>{t("donts")}</li>
            </ul>
          </AboutCard>
          <AboutCard
            className={css.AboutCardStyle}
            icon={<TipFilled />}
            title={t("create-tips")}
            text=""
          >
            <span className={css.AboutColor}>{t("tip-short")}</span>
            <br />
            <ul>
              <li>{t("specific-dish")}</li>
              <li>{t("specific-person")}</li>
              <li>{t("specific-room")}</li>
              <li>{t("specific-cocktail")}</li>
            </ul>
          </AboutCard>
          <AboutCard
            className={css.AboutCardStyle}
            icon={<StarFilled />}
            title={t("borrow-tips")}
            text={t("borrow-tips-text")}
          />
          <AboutCard
            className={css.AboutCardStyle}
            icon={<ListCheckFilled />}
            title={t("follow-lists")}
            text={t("follow-lists-text")}
          />
          <AboutCard
            className={css.AboutCardStyle}
            icon={<GlobeFilled />}
            title={t("my-universe")}
            text={t("my-universe-text")}
          />
          <AboutCard
            className={css.AboutCardStyle}
            icon={<CompassFilled />}
            title={t("the-explorer")}
            text=""
          >
            <span className={css.AboutColor}>{t("the-explorer-short")}</span>
            <br />
            <ul>
              <li>{t("the-explorer-text-1")}</li>
              <li>{t("the-explorer-text-2")}</li>
              <li>{t("the-explorer-text-3")}</li>
              <li>{t("the-explorer-text-4")}</li>
            </ul>
          </AboutCard>
        </div>
      </section>
      <Footer />
    </>
  );
}
