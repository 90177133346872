import Cards from "Components/Cards";
import Icons from "Components/Icons";
import ModalPortal from "Components/UI/ContextMenu/ModalPortal";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import React from "react";
import MobileHeader from "Views/AboutView/MobileHeader";
import css from "./MobileMapDetails.module.css";
import { useTranslation } from "react-i18next";

type tipDetailsType = {
  tip: TipModel;
  tipList: TipListModel;
  setOpenDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileMapDetails = ({ tip, tipList, setOpenDetails }: tipDetailsType) => {
  const { t } = useTranslation();
  return (
    <ModalPortal selector={"#portal"}>
      <article className={css.Container}>
        <MobileHeader title={""} ClickItem={setOpenDetails} />
        <Cards.Tip
          value={tip}
          key={tip.id}
          className={css.Tip}
          tipList={tipList}
          mapDetails={true}
        />
        <ul className={css.Info}>
          {tip.venue?.address && (
            <li>
              <p>
                <Icons.LocationNew className={css.Icon} />
              </p>
              <p>{tip.venue.address}</p>
            </li>
          )}

          {tip.venue?.phone && (
            <li>
              <Icons.PhoneNew className={css.Icon} />
              <p>{tip.venue.phone}</p>
            </li>
          )}

          {tip.venue?.website && (
            <li>
              <Icons.WebNew className={css.Icon} />
              <p>
                <a
                  href={tip.venue.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("website")}
                </a>
              </p>
            </li>
          )}
        </ul>
      </article>
    </ModalPortal>
  );
};

export default MobileMapDetails;
